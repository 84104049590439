// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Button from "../../../styleguide/components/Button/Button.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UserListCloudRackSearchAndExportCss from "./UserListCloudRackSearchAndExportCss.res.js";

function UserListCloudRackSearchAndExport(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                props.searchComponent,
                JsxRuntime.jsx(Button.make, {
                      size: "MD",
                      color: "Teal",
                      onClick: props.onExportButtonClick,
                      children: "Export"
                    })
              ],
              className: UserListCloudRackSearchAndExportCss.container
            });
}

var Css;

var make = UserListCloudRackSearchAndExport;

export {
  Css ,
  make ,
}
/* Button Not a pure module */
