// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Hooks from "../../../libs/Hooks.res.js";
import * as Table from "../../../styleguide/components/Table/Table.res.js";
import * as React from "react";
import * as Select from "../../../styleguide/forms/Select/Select.res.js";
import * as Control from "../../../styleguide/components/Control/Control.res.js";
import * as Spinner from "../../../styleguide/components/Spinner/Spinner.res.js";
import * as TableRow from "../../../styleguide/components/Table/TableRow.res.js";
import * as Container from "../../../styleguide/components/Container/Container.res.js";
import * as TableBody from "../../../styleguide/components/Table/TableBody.res.js";
import * as TableCell from "../../../styleguide/components/Table/TableCell.res.js";
import * as TextField from "../../../styleguide/forms/TextField/TextField.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pagination from "../../../styleguide/components/Pagination/Pagination.res.js";
import * as IconSorting from "../../../styleguide/icons/IconSorting.res.js";
import * as TableHeader from "../../../styleguide/components/Table/TableHeader.res.js";
import * as TableHeaderCell from "../../../styleguide/components/Table/TableHeaderCell.res.js";
import * as DashboardTableCss from "../../../styleguide/dashboard/components/table/DashboardTableCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UserListCloudRackSearchAndExport from "./UserListCloudRackSearchAndExport.res.js";
import * as UserListCloudRackTabControlPlaneCss from "./UserListCloudRackTabControlPlaneCss.res.js";
import * as UserListCloudRackControlPlaneReducer from "./UserListCloudRackControlPlaneReducer.res.js";

var initialState = {
  currentPage: 1,
  totalPages: 1,
  currentUsers: undefined,
  filterByStatus: undefined,
  searchTerm: undefined,
  sortBy: {
    NAME: "Created",
    VAL: "Asc"
  }
};

function UserListCloudRackTabControlPlane(props) {
  var match = Hooks.useReducer(initialState, UserListCloudRackControlPlaneReducer.reducer);
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          dispatch("FetchUsers");
        }), []);
  var searchTerm = state.searchTerm;
  var filter = state.filterByStatus;
  var match$1 = state.sortBy;
  var match$2 = state.sortBy;
  var currentUsers = state.currentUsers;
  return JsxRuntime.jsxs(Container.make, {
              className: UserListCloudRackTabControlPlaneCss.container,
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(UserListCloudRackSearchAndExport.make, {
                            searchComponent: JsxRuntime.jsxs("div", {
                                  children: [
                                    JsxRuntime.jsx(TextField.make, {
                                          id: "search",
                                          value: searchTerm !== undefined ? searchTerm : "",
                                          placeholder: "Search by name, email",
                                          className: UserListCloudRackTabControlPlaneCss.textField,
                                          onChange: (function ($$event) {
                                              dispatch({
                                                    TAG: "Search",
                                                    _0: $$event.target.value
                                                  });
                                            })
                                        }),
                                    JsxRuntime.jsxs(Select.make, {
                                          id: "status",
                                          value: filter !== undefined ? UserListCloudRackControlPlaneReducer.statusFilterToJs(filter) : "",
                                          onChange: (function ($$event) {
                                              var value = $$event.target.value;
                                              dispatch({
                                                    TAG: "FilterByStatus",
                                                    _0: value === "" ? undefined : value
                                                  });
                                            }),
                                          children: [
                                            JsxRuntime.jsx(Select.DefaultOption.make, {
                                                  selectable: true,
                                                  children: "Status"
                                                }),
                                            JsxRuntime.jsx(Select.$$Option.make, {
                                                  value: UserListCloudRackControlPlaneReducer.statusFilterToJs("Active"),
                                                  children: "Active"
                                                }),
                                            JsxRuntime.jsx(Select.$$Option.make, {
                                                  value: UserListCloudRackControlPlaneReducer.statusFilterToJs("Pending"),
                                                  children: "Pending"
                                                })
                                          ]
                                        })
                                  ],
                                  className: UserListCloudRackTabControlPlaneCss.searchRow
                                }),
                            onExportButtonClick: (function (param) {
                                dispatch("Export");
                              })
                          }),
                      className: UserListCloudRackTabControlPlaneCss.searchAndExportRow
                    }),
                JsxRuntime.jsxs("section", {
                      children: [
                        JsxRuntime.jsxs(Table.make, {
                              className: DashboardTableCss.table,
                              children: [
                                JsxRuntime.jsx(TableHeader.make, {
                                      children: JsxRuntime.jsxs(TableRow.make, {
                                            children: [
                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                    children: JsxRuntime.jsx(Control.make, {
                                                          onClick: (function (param) {
                                                              dispatch("SortByCreated");
                                                            }),
                                                          children: JsxRuntime.jsxs("span", {
                                                                children: [
                                                                  "Created",
                                                                  JsxRuntime.jsx(IconSorting.make, {
                                                                        size: "XXS",
                                                                        direction: typeof match$1 === "object" && match$1.NAME === "Created" ? match$1.VAL : undefined
                                                                      })
                                                                ],
                                                                className: DashboardTableCss.controlCell
                                                              })
                                                        })
                                                  }),
                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                    children: JsxRuntime.jsx(Control.make, {
                                                          onClick: (function (param) {
                                                              dispatch("SortByName");
                                                            }),
                                                          children: JsxRuntime.jsxs("span", {
                                                                children: [
                                                                  "Name",
                                                                  JsxRuntime.jsx(IconSorting.make, {
                                                                        size: "XXS",
                                                                        direction: typeof match$2 === "object" && match$2.NAME === "Name" ? match$2.VAL : undefined
                                                                      })
                                                                ],
                                                                className: DashboardTableCss.controlCell
                                                              })
                                                        })
                                                  }),
                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                    children: "Email"
                                                  }),
                                              JsxRuntime.jsx(TableHeaderCell.make, {
                                                    children: "Status"
                                                  })
                                            ]
                                          })
                                    }),
                                JsxRuntime.jsx(TableBody.make, {
                                      children: currentUsers !== undefined ? (
                                          currentUsers.length === 0 ? JsxRuntime.jsx(TableRow.make, {
                                                  className: DashboardTableCss.fetchError,
                                                  children: JsxRuntime.jsx(TableCell.make, {
                                                        colSpan: 4,
                                                        children: "No users were found."
                                                      })
                                                }) : Belt_Array.mapWithIndex(currentUsers, (function (index, row) {
                                                    var created_at = row.created_at;
                                                    return JsxRuntime.jsxs(TableRow.make, {
                                                                children: [
                                                                  JsxRuntime.jsx(TableCell.make, {
                                                                        children: created_at !== undefined ? created_at : null
                                                                      }),
                                                                  JsxRuntime.jsx(TableCell.make, {
                                                                        children: row.name
                                                                      }),
                                                                  JsxRuntime.jsx(TableCell.make, {
                                                                        children: row.email
                                                                      }),
                                                                  JsxRuntime.jsx(TableCell.make, {
                                                                        children: row.status
                                                                      })
                                                                ]
                                                              }, String(index));
                                                  }))
                                        ) : JsxRuntime.jsx("div", {
                                              children: JsxRuntime.jsx(Spinner.make, {
                                                    size: "XL",
                                                    color: "Teal"
                                                  }),
                                              className: DashboardTableCss.spinner
                                            })
                                    })
                              ]
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(Pagination.make, {
                                    currentPage: state.currentPage,
                                    totalPages: state.totalPages,
                                    onPageClick: (function (pageNumber) {
                                        dispatch({
                                              TAG: "ChangePage",
                                              _0: pageNumber
                                            });
                                      })
                                  }),
                              className: DashboardTableCss.pagination
                            })
                      ],
                      className: DashboardTableCss.tableWrapper
                    })
              ]
            });
}

var Css;

var TableCss;

var make = UserListCloudRackTabControlPlane;

export {
  Css ,
  TableCss ,
  initialState ,
  make ,
}
/* Hooks Not a pure module */
