// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var searchRow = Cx.cx([
      "flex",
      "md:flex-row",
      "flex-col",
      "md:items-center",
      "gap-2"
    ]);

var textField = Cx.cx([
      "w-full",
      "lg:w-90!",
      "md:w-80!"
    ]);

var container = Cx.cx([
      "grid",
      "w-full"
    ]);

var searchAndExportRow = "my-7.5";

var tableBodyMinHeight = "min-h-3xs";

export {
  searchAndExportRow ,
  searchRow ,
  textField ,
  tableBodyMinHeight ,
  container ,
}
/* searchRow Not a pure module */
