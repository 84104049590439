// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var container = Cx.cx([
      "flex",
      "lg:flex-row",
      "flex-col",
      "md:items-start",
      "justify-between",
      "gap-2"
    ]);

export {
  container ,
}
/* container Not a pure module */
