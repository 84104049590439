// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Link from "../../../styleguide/components/Link/Link.res.js";
import * as React from "react";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardNavbarCss from "../../../styleguide/dashboard/components/navbar/DashboardNavbarCss.res.js";
import * as UserListCloudRackTabZenlayer from "./UserListCloudRackTabZenlayer.res.js";
import * as UserListCloudRackTabControlPlane from "./UserListCloudRackTabControlPlane.res.js";

function UserListCloudRackTabs(props) {
  var match = React.useState(function () {
        return "ControlPlane";
      });
  var setActiveTab = match[1];
  var activeTab = match[0];
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsxs("div", {
                                  children: [
                                    JsxRuntime.jsx("div", {
                                          children: JsxRuntime.jsx(Link.make, {
                                                href: "#",
                                                className: Cx.cx([activeTab === "ControlPlane" ? DashboardNavbarCss.underlinedLinkItem : DashboardNavbarCss.normalLinkItem]),
                                                children: "Control Plane"
                                              }),
                                          className: Cx.cx([activeTab === "ControlPlane" ? DashboardNavbarCss.underlinedLinkContainer : DashboardNavbarCss.linkContainer]),
                                          onClick: (function (param) {
                                              setActiveTab(function (param) {
                                                    return "ControlPlane";
                                                  });
                                            })
                                        }),
                                    JsxRuntime.jsx("div", {
                                          children: JsxRuntime.jsx(Link.make, {
                                                href: "#",
                                                className: Cx.cx([activeTab === "Zenlayer" ? DashboardNavbarCss.underlinedLinkItem : DashboardNavbarCss.normalLinkItem]),
                                                children: "Zenlayer"
                                              }),
                                          className: Cx.cx([activeTab === "Zenlayer" ? DashboardNavbarCss.underlinedLinkContainer : DashboardNavbarCss.linkContainer]),
                                          onClick: (function (param) {
                                              setActiveTab(function (param) {
                                                    return "Zenlayer";
                                                  });
                                            })
                                        })
                                  ],
                                  className: DashboardNavbarCss.barContainer
                                }),
                            className: DashboardNavbarCss.barWrapperMargin
                          }),
                      className: DashboardNavbarCss.subnavContainerGeneral
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        activeTab === "ControlPlane" ? JsxRuntime.jsx(UserListCloudRackTabControlPlane.make, {}) : null,
                        activeTab === "Zenlayer" ? JsxRuntime.jsx(UserListCloudRackTabZenlayer.make, {}) : null
                      ]
                    })
              ],
              className: DashboardNavbarCss.barWrapperGeneral
            });
}

var Css;

var make = UserListCloudRackTabs;

export {
  Css ,
  make ,
}
/* Link Not a pure module */
