// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H1 from "../../../styleguide/components/Heading/H1.res.js";
import * as DashboardIndexCss from "../../../styleguide/dashboard/components/views/index/DashboardIndexCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UserListCloudRackTabs from "./UserListCloudRackTabs.res.js";

function UserListCloudRack(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx(H1.make, {
                            children: "Cloud Rack Users"
                          }),
                      JsxRuntime.jsx(UserListCloudRackTabs.make, {})
                    ],
                    className: DashboardIndexCss.layout
                  }),
              className: DashboardIndexCss.pageContainer
            });
}

var Css;

var make = UserListCloudRack;

var $$default = UserListCloudRack;

export {
  Css ,
  make ,
  $$default as default,
}
/* H1 Not a pure module */
